<template>
    <div class="login">
        <div>
            <div class="login-from">
                <div class="login-from-sao"></div>
            </div>
            <div class="login-from-body">
                <div class="form-title">{{$bjggs.APP_NAME}}</div>
                <el-form ref="loginForm" :model="loginForm" label-width="30px" :hide-required-asterisk=true
                         :rules="rules">
                    <el-form-item prop="tenantKey">
                        <i slot="label" class="el-icon-monitor login-input-icon"></i>
                        <el-select placeholder="请选择目标系统"
                                   no-data-text="没有已注册的系统，请通过分发系统管理平台注册"
                                   filterable
                                   v-model="loginForm.tenantKey"
                                   style="width: 100%" ref='selectLable'>
                            <el-option
                                    v-for="tenant in tenantOptions"
                                    :key="tenant.key"
                                    :label="tenant.value"
                                    :value="tenant.key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="account">
                        <i slot="label" class="el-icon-user login-input-icon"></i>
                        <el-input type="text" placeholder="请输入用户名" v-model="loginForm.account"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <i slot="label" class="el-icon-key login-input-icon"></i>
                        <el-input show-password placeholder="请输入密码" v-model="loginForm.password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <i slot="label" class="el-icon-guide login-input-icon"></i>
                        <drag-verify @onValidation="dragver" ref="dragver"/>
                    </el-form-item>
                </el-form>
                <div class="login-from-button">
                    <el-button :loading="loading" round @click="submitForm">立即登录</el-button>
                </div>
            </div>
        </div>
        <div class="footer-new">
<!--            <p><a href="http://www.bjggs.com.cn/" target="_blank">技术支持：北京良安科技有限公司</a></p>-->
        </div>
    </div>
</template>

<script>
    import {ruleBuilder} from '@/util/validate'
    import {getRegisteredTenantOptions} from '@/service/manage/multi-tenant'
    import VueRouter from 'vue-router'
    import { Base64 } from 'js-base64';
    import {LOCAL_STORAGE} from "@/util/storage";
    import  MD5  from 'js-md5';
    import DragVerify from "@/components/login/DragVerify";

    const {isNavigationFailure} = VueRouter;

    export default {
        components:{DragVerify},
        props: ['redirect'],
        data() {
            return {
                loginForm: {
                    account: '',
                    password: '',
                    tenantKey: null,
                    tenantName: ''
                },
                loading: false,
                tenantOptions: [],
                rules: {
                    tenantKey: [ruleBuilder.required(...['string', 'blur', '请选择目标系统'])],
                    account: [ruleBuilder.required(...['string', 'blur', '请输入用户名'])],
                    password: [ruleBuilder.required(...['string', 'blur', '请输入密码']), ruleBuilder.range(1, 30)]
                },
                dragVerifyConfirm: false
            };
        },
        mounted() {
            // 绑定enter事件
            this.enterKeyup();
        },
        destroyed() {
            // 销毁enter事件
            this.enterKeyupDestroyed();
        },
        methods: {
            enterKey(event) {
                const code = event.keyCode
                    ? event.keyCode
                    : event.which
                        ? event.which
                        : event.charCode;
                if (code == 13) {
                    this.submitForm();
                }
            },
            enterKeyupDestroyed() {
                document.removeEventListener("keyup", this.enterKey);
            },
            enterKeyup() {
                document.addEventListener("keyup", this.enterKey);
            },
            submitForm() {
                if(!this.dragVerifyConfirm) {
                    this.$msgError("请拖动滑块进行验证！")
                    return
                }
                this.loginForm.tenantName = this.$refs.selectLable.selected.label;
                LOCAL_STORAGE.set("initTenantKey", this.loginForm.tenantKey);
                LOCAL_STORAGE.set("initTenantName", this.loginForm.tenantName);
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        // this.$store.dispatch('user/login', {...this.loginForm, password: Base64.encode(this.loginForm.password)})
                        this.$store.dispatch('user/login', {...this.loginForm, password: MD5(this.loginForm.password)})//等保修改
                            .then(() => {
                                this.enterKeyupDestroyed();
                                this.$router.push(this.redirect || "/guideJL").catch((error) => {
                                    //暂时忽略导航重定向error
                                    if (!isNavigationFailure(error)) {
                                        throw error;
                                    }
                                });
                            }).catch(err =>{
                           this.$refs.dragver.clean();
                        })
                            .finally(() => this.loading = false)
                    } else {
                        return false;
                    }
                });
            },
            dragver(param){
                this.dragVerifyConfirm = param;
            }
        },
        created() {
            getRegisteredTenantOptions().then(({data}) => {
                if (!data || !data.length) {
                    this.$msgError('没有已注册的系统，请通过分发系统管理平台注册至少一个系统之后再次尝试从本页面登录！');
                }
                this.tenantOptions = data;
                this.loginForm.tenantKey = LOCAL_STORAGE.get("initTenantKey") ? LOCAL_STORAGE.get("initTenantKey") : data[0].key;
                this.loginForm.tenantName = LOCAL_STORAGE.get("initTenantName") ? LOCAL_STORAGE.get("initTenantKey") : data[0].value;
            });
        }
    }
</script>
<style lang="scss" scoped>

    html, body {
        margin: 0;
    }

    .login {
        width: 100%;
        height: 100%;
        background: url('../assets/images/login-bg.png') no-repeat;
        background-size: 100% 100%;
    }

    .login-from {
        width: 600px;
        height: 528px;
        background: url("../assets/images/login-form-bg.png") no-repeat;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -264px;
        margin-left: -300px;
        opacity: 0.7;
    }

    .login-from-sao {
        width: 493px;
        height: 493px;
        background: url("../assets/images/login-form-sao1.png") no-repeat;
        top: 11px;
        position: absolute;
        left: 48px;
        -webkit-transform: rotate(360deg);
        animation: rotation 10s linear infinite;
        -moz-animation: rotation 10s linear infinite;
        -webkit-animation: rotation 10s linear infinite;
        -o-animation: rotation 10s linear infinite;
    }

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .login-from-body {
        position: absolute;
        width: 260px;
        height: 276px;
        top: 50%;
        left: 50%;
        margin-top: -185px;
        margin-left: -140px;
    }

    .form-title {
        height: 75px;
        line-height: 75px;
        color: #FFFFFF;
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    .login-from-button {
        height: 40px;
        line-height: 40px;
        text-align: center;
    }

    .footer-new {
        width: 100%;
        height: 66px;
        line-height: 66px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        position: fixed;
        bottom: 0;
    }

    .footer-new p {
        display: block;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        text-align: center;
        font-size: 20px;
    }

    .footer-new a {
        color: #fff;
        font-family: 幼圆;
    }
    .el-form-item{
        margin-bottom: 15px;
    }
</style>
